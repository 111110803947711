import { Link } from 'react-router-dom';

const CallToAction = () => {
  return (
    <section id='cta' className='bg-green-800'>
      {/* Flex Container */}
      <div className='container flex flex-col items-center justify-between px-6 py-24 mx-auto space-y-12 md:py-12 md:flex-row md:space-y-0'>
        {/* Heading */}
        <h2 className='text-5xl font-bold leading-tight text-center text-white md:text-4xl  md:text-left'>
          Top Deals from Trusted Tablets Online. Handpicked Favorites Just for You. Visit Us Today!
        </h2>
        {/* Button */}
        <div>
       
        </div>
      </div>
    </section>
  );
};

export default CallToAction;
