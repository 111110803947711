import React from 'react';

const Features = () => {
  return (
    <section id='features'>
      {/* Flex Container */}
      <div className='container flex flex-col px-4 mx-auto mt-10 space-y-12 md:space-y-0 md:flex-row'>
        {/* What's Different */}
        <div className='flex flex-col space-y-12 md:w-1/2'>
          <h2 className='max-w-md text-4xl font-bold text-center md:text-left'>
            Discreet Packaging
          </h2>
          <p className='text-center text-darkGrayishBlue md:text-left'>
            At Trusted Tablets, we prioritize your privacy with our discreet packaging and delivery.
            Our approach ensures that all your orders are handled with the utmost confidentiality, 
            from fast shipping to anonymous packaging. We respect the sensitive nature of 
            our products and are committed to protecting your privacy every step of the way.
          </p>
        </div>

        {/* Numbered List */}
        <div className='flex flex-col space-y-8 md:w-1/2'>
          {/* List Item 1 */}
          <div className='flex flex-col space-y-3 md:space-y-0 md:space-x-6 md:flex-row'>
            {/* Heading */}
            <div className='rounded-l-full bg-green-200 md:bg-transparent'>
              <div className='flex items-center space-x-2'>
                <div className='px-4 py-2 text-white rounded-full md:py-1 bg-green-800'>
                  01
                </div>
                <h3 className='text-base font-bold md:mb-4 md:hidden'>
                  Fast and Private Delivery
                </h3>
              </div>
            </div>

            <div>
              <h3 className='hidden mb-4 text-lg font-bold md:block'>
                Fast and Private Delivery
              </h3>
              <p className='text-darkGrayishBlue'>
                We guarantee swift delivery of your medications with complete discretion. Your order arrives quickly, and only you will know what’s inside.
              </p>
            </div>
          </div>

          {/* List Item 2 */}
          <div className='flex flex-col space-y-3 md:space-y-0 md:space-x-6 md:flex-row'>
            {/* Heading */}
            <div className='rounded-l-full bg-green-200 md:bg-transparent'>
              <div className='flex items-center space-x-2'>
                <div className='px-4 py-2 text-white rounded-full md:py-1 bg-green-800'>
                  02
                </div>
                <h3 className='text-base font-bold md:mb-4 md:hidden'>
                  No Identifying Marks
                </h3>
              </div>
            </div>

            <div>
              <h3 className='hidden mb-4 text-lg font-bold md:block'>
                No Identifying Marks
              </h3>
              <p className='text-darkGrayishBlue'>
                Our packaging is designed to be completely anonymous. There are no logos, pharmacy names, or descriptions of the contents.
              </p>
            </div>
          </div>

          {/* List Item 3 */}
          <div className='flex flex-col space-y-3 md:space-y-0 md:space-x-6 md:flex-row'>
            {/* Heading */}
            <div className='rounded-l-full bg-green-200 md:bg-transparent'>
              <div className='flex items-center space-x-2'>
                <div className='px-4 py-2 text-white rounded-full md:py-1 bg-green-800'>
                  03
                </div>
                <h3 className='text-base font-bold md:mb-4 md:hidden'>
                  Respect for Your Privacy
                </h3>
              </div>
            </div>

            <div>
              <h3 className='hidden mb-4 text-lg font-bold md:block'>
                Respect for Your Privacy
              </h3>
              <p className='text-darkGrayishBlue'>
                We understand that many of our products are sensitive in nature. That’s why we take every measure to ensure your purchases remain confidential.
              </p>
            </div>
          </div>
        </div>
      </div>

      {/* Additional Content */}
      <div className='container flex flex-col px-4 mx-auto mt-10 space-y-12'>
        <h2 className='text-3xl font-bold text-center'>Trusted Tablets | Your Go-To Online Pharmacy for Generic ED Medications</h2>

        <div className='flex flex-col space-y-6'>
          <h3 className='text-2xl font-semibold'>Why Choose Trusted Tablets?</h3>
          <p className='text-darkGrayishBlue'>
            At Trusted Tablets, we’re committed to making healthcare accessible to everyone. We understand the importance of affordable medications, and that’s why we offer generic ED drugs at flat 50% off, making quality healthcare easier to reach. With prices typically 50% to 70% lower than those of US-based pharmacies, we ensure that cost is never a barrier to getting the treatment you need. Our products are sourced directly from trusted manufacturers in Europe and India, guaranteeing both quality and affordability.
          </p>

          <h3 className='text-2xl font-semibold'>Quality You Can Trust</h3>
          <p className='text-darkGrayishBlue'>
            Despite our low prices, we never compromise on quality. Our generic medications are manufactured using the same high-quality ingredients and standards as their brand-name counterparts. We ensure that all products are shipped in their original, unopened manufacturer packaging, preserving their integrity and expiration dates. This commitment to quality extends from production to delivery, ensuring that you receive the best possible care.
          </p>

          <h3 className='text-2xl font-semibold'>What Sets Us Apart in ED Treatment?</h3>
          <p className='text-darkGrayishBlue'>
            Trusted Tablets excels in providing effective, affordable solutions for erectile dysfunction (ED). We specialize in generic ED medications that offer real results, helping countless individuals regain control over their health. As a leading online pharmacy, we serve a vast customer base and continually expand our offerings to meet the growing demand for accessible healthcare. Our focus on delivering effective and affordable treatments makes us a trusted name in the field of ED care.
          </p>

          <h3 className='text-2xl font-semibold'>Compliance and Flexibility</h3>
          <p className='text-darkGrayishBlue'>
            We partner with the world’s top pharmaceutical companies to offer a diverse range of over 500 generic drugs. This collaboration not only allows us to provide a wide selection of medications but also ensures that we operate with full compliance to legal standards. Our commitment to legality and flexibility highlights our dedication to professionalism and efficiency in serving our customers.
          </p>

          <h3 className='text-2xl font-semibold'>Why Trusted Tablets?</h3>
          <p className='text-darkGrayishBlue'>
            Choosing Trusted Tablets means opting for a pharmacy that values quality, affordability, and customer satisfaction. We stand out by offering low prices without sacrificing quality, fostering strong partnerships with reputable pharmaceutical companies, and ensuring a seamless, reliable way to meet your medication needs. Trusted Tablets is your trusted partner in health and wellness, dedicated to providing the best for our customers.
          </p>
        </div>
      </div>
    </section>
  );
};

export default Features;
