import { Link } from 'react-router-dom';

import companyLogoWhite from '../assets/images/logo.svg';


const Footer = () => {
  return (
    <div className='bg-veryDarkBlue'>
      {/* Flex Container */}
      <div className='container flex flex-col-reverse justify-between px-6 py-10 mx-auto space-y-8 md:flex-row md:space-y-0'>
        {/* Logo and social links container */}
        <div className='flex flex-col-reverse items-center justify-between space-y-12 md:flex-col md:space-y-0 md:items-start'>
          
          {/* Logo */}
          <div>
            <img src={companyLogoWhite} style={{ width: '214px', height: '42px' }} className='invert h-8' alt='TrustedTablets' />
          </div>
          {/* Social Links Container */}
          <div className='flex justify-center space-x-4'>
            {/* Add social links here if needed */}
          </div>
        </div>

        {/* Input Container */}
        <div className='flex flex-col justify-between'>
          <form>
            <div className='flex space-x-3'>
              <input
                type='text'
                className='flex-1 px-4 rounded-full focus:outline-none'
                placeholder='Updated in your inbox'
              />
              <button className='px-6 py-2 text-white rounded-full bg-green-800 hover:bg-green-400 focus:outline-none'>
                Go
              </button>
            </div>
          </form>
        </div>
      </div>

      {/* Footer Text Container */}
	  <div className='text-center text-white py-4'>
        <div className='max-w-7xl mx-auto px-4'>
Welcome to our specialized online pharmacy, where we address all your healthcare needs with care and expertise. As your trusted partner in health, we offer a comprehensive range of services designed just for you. Our team of skilled professionals is committed to delivering personalized care to meet your individual needs      </div>
      <div className='text-center text-white py-4'>
        Copyright © 2022, All Rights Reserved
      </div>
    </div>
	</div>
  );
};

export default Footer;
