import { Link } from 'react-router-dom';

import illustrationIntro from '../assets/images/illustration-intro.webp';

const Hero = () => {
  return (
    <section id='hero'>
      {/* Flex Container */}
      <div className='container flex flex-col-reverse items-center px-6 mx-auto mt-10 space-y-0 md:space-y-0 md:flex-row'>
        {/* Left Item */}
        <div className='flex flex-col mb-32 space-y-12 md:w-1/2'>
          <h1 className=' text-4xl font-bold text-center md:text-5xl md:text-left'>
            Trusted Tablets | High-Quality ED Medications at Affordable Prices
          </h1>
		  
          <p className='max-w-sm text-xl text-center text-darkGrayishBlue md:text-left'>
            Affordable Euro Meds for ED – Quality Generics That Deliver Results!
          </p>
		            <div className='flex justify-center md:justify-start'>
  <a
    href='https://trustedtablets.online/'
    className='p-3 px-6 pt-2 text-white bg-green-800 rounded-full baseline hover:bg-emerald-600'
    target='_blank'
    rel='noopener noreferrer'
  >
    Explore More
  </a>
</div>
		  <p className=' text-center text-darkGrayishBlue md:text-left'>
            At Trusted Tablets, we know how crucial it is to have access to effective medications without overspending. Our goal is to offer high-quality generics for Erectile Dysfunction (ED) that provide reliable results while being budget-friendly.
          </p>

        </div>
        {/* Image */}
        <div className='md:w-1/2'>
          <img src={illustrationIntro} alt='' />
        </div>
      </div>
    </section>
  );
};

export default Hero;
