import { Link } from 'react-router-dom';

import avatarAnisha from '../assets/images/avatar-anisha.png';
import avatarAli from '../assets/images/avatar-ali.png';
import avatarRichard from '../assets/images/avatar-richard.png';

const Testimonial = () => {
  return (
    <section id='testimonials'>
      {/* Container to heading and testm blocks */}
      <div className='max-w-6xl px-5 mx-auto mt-32 text-center'>
        {/* Heading */}
        <h2 className='text-4xl font-bold text-center'>
          Hear What Our Customers Are Saying
        </h2>
        {/* Testimonials Container */}
        <div className='flex flex-col mt-24 md:flex-row md:space-x-6'>
          {/* Testimonial 1 */}
          <div className='flex flex-col items-center p-6 space-y-6 rounded-lg bg-veryLightGray md:w-1/3'>
            <img src={avatarAnisha} className='w-16 -mt-14' alt='' />
            <h5 className='text-lg font-bold'>Maëlle Chambaz</h5>
            <p className='text-sm text-darkGrayishBlue'>
              “I just received my latest order from Trusted Tablets, and I’ve been a loyal customer for the past five years, with six or seven orders under my belt. They’ve never disappointed me—my packages always arrive on time, sometimes even earlier. Their selection is unmatched, and the quality of their products is always outstanding. Plus, you won’t find better prices anywhere else..”
            </p>
          </div>

          {/* Testimonial 2 */}
          <div className='hidden flex-col items-center p-6 space-y-6 rounded-lg bg-veryLightGray md:flex md:w-1/3'>
            <img src={avatarAli} className='w-16 -mt-14' alt='' />
            <h5 className='text-lg font-bold'>Viola Puddinu</h5>
            <p className='text-sm text-darkGrayishBlue'>
              “I’ve been a customer since the company was based in the Netherlands, and with their new branch in Dubai, my experience has only gotten better. Delivery times are now twice as fast, and the savings compared to retail pharmacies are substantial. I recommended the company to a friend, and she’s now a loyal customer too..”
            </p>
          </div>

          {/* Testimonial 3 */}
          <div className='hidden flex-col items-center p-6 space-y-6 rounded-lg bg-veryLightGray md:flex md:w-1/3'>
            <img src={avatarRichard} className='w-16 -mt-14' alt='' />
            <h5 className='text-lg font-bold'>Michel Magistris</h5>
            <p className='text-sm text-darkGrayishBlue'>
              “I was very impressed with the website’s ease of navigation, user-friendliness, and, of course, the prices. Customer support was excellent, and the delivery, packaging, and timing were spot on. However, one area for improvement: products that are out of stock should be more clearly marked. Right now, they’re listed with just a yellow/orange text in the middle of the description, which can be easy to overlook.”
            </p>
          </div>
        </div>
        <div className='my-16'>
        </div>
      </div>
    </section>
  );
};

export default Testimonial;
