import { useState } from 'react';
import { Link } from 'react-router-dom';

import companyLogo from '../assets/images/logo.svg';

const Navbar = () => {
  const [toggleMenu, setToggleMenu] = useState(false);

  return (
    <nav className='relative container mx-auto p-6'>
      {/* Flex Container */}
      <div className='flex items-center justify-between'>
        {/* Logo */}
        <div className='pt-2'>
          <img src={companyLogo} alt='Company Logo' style={{ width: '214px', height: '42px' }} />
        </div>
        
        {/* Button */}
        <a
          href='https://trustedtablets.online/'
          className='hidden p-3 px-6 pt-2 text-white bg-green-800 rounded-full baseline hover:bg-emerald-600 md:block'
          target='_blank'
          rel='noopener noreferrer nofollow'
        >
          Online Pharmacy
        </a>

        {/* Hamburger Icon */}
        <button
          className={
            toggleMenu
              ? 'open block hamburger md:hidden focus:outline-none'
              : 'block hamburger md:hidden focus:outline-none'
          }
          onClick={() => setToggleMenu(!toggleMenu)}
        >
          <span className='hamburger-top'></span>
          <span className='hamburger-middle'></span>
          <span className='hamburger-bottom'></span>
        </button>
      </div>

      {/* Mobile Menu */}
      <div className='md:hidden'>
        <div
          className={
            toggleMenu
              ? 'absolute flex flex-col items-center self-end py-8 mt-10 space-y-6 font-bold bg-white sm:w-auto sm:self-center left-6 right-6 drop-shadow-md'
              : 'absolute flex-col items-center hidden self-end py-8 mt-10 space-y-6 font-bold bg-white sm:w-auto sm:self-center left-6 right-6 drop-shadow-md'
          }
        >
          <a
            href='https://trusted-tablets-online.com/'
            target='_blank'
            rel='noopener noreferrer'
          >
            Go TO PHARMACY
          </a>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
